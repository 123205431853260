import { Component,OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

  import { HttpClient } from '@angular/common/http';
  import { Observable } from 'rxjs';
  import { tap } from 'rxjs/internal/operators/tap';
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {


  data;
  msg='';
  is_loding=false;
  constructor(private httpClient: HttpClient,private route: ActivatedRoute) {
  }
  ngOnInit() {

    this.route.paramMap.subscribe(async (paramMap) => {
      this.getData(paramMap.get('order_id'),paramMap.get('user_id')).subscribe(async (data) => {
        if(data['code']==200)
        this.data=data['data'];
        else
        this.msg=data['message'];

        this.is_loding=true;
      });


    });

  }

  getData(order_id,user_id)
  {
    // maqdaktest.top
  return this.httpClient.get(`https://maqdak.top/api/order/${order_id}/invoice/${user_id}`).pipe(
    tap(async (res) => {
      return res;
    })
  );
  }

}
