<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



<!-- Toolbar -->
<div class="toolbar" role="banner">
  <img
    width="40"
    alt="Maqdak Logo"
    src="http://maqdak.com/images/logo-maqdak.png"
  />
  <h3>مقضاك</h3>

</div>

<!-- Site wrapper -->


<div style="margin-top:200px;text-align:center">
  <h5>شكرا لتسوقك فى مقضاك</h5>
</div>
