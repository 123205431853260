<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



<!-- Toolbar -->
<div class="toolbar" role="banner">
  <img
    width="40"
    alt="Maqdak Logo"
    src="http://maqdak.com/images/logo-maqdak.png"
  />
  <h3>مقضاك</h3>

</div>

<!-- Site wrapper -->

<div *ngIf="is_loding" class="wrapper">
<!-- Left side column. contains the sidebar -->
  <div class="first"></div>

<!-- =============================================== -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
      </h1>
    </section>

    <!-- Main content -->


<section *ngIf="data" class="container" style="margin-top:80px">
    <!-- title row -->
    <div class="row mb-5">
        <div class="col-xs-12 m-auto " >
            <h2 class="page-header">
                <i class="fa fa-globe"></i> فاتورة
                <!-- <small class="pull-right">  التاريخ: 2020-04-16 16:53:47 </small> -->
            </h2>
        </div>
        <!-- /.col -->
    </div>
    <!-- info row -->
    <div *ngIf="data" class="">


        <div class="row invoice-info">
            <div class="col-sm-4 invoice-col">
                من
                <address>
                    <strong>مقضاك.</strong><br>



                </address>
            </div>
            <!-- /.col -->
            <div class="col-sm-4 invoice-col">
                الى
                <address>
                    <strong>{{data.user.name}}</strong><br>
                    <br>

                    <span *ngIf="data.address[0].phone">  <b>الهاتف:</b> {{data.address[0].phone}} <br></span>
                    <span *ngIf="!data.address[0].phone">  <b>الهاتف:</b> {{data.user.phone}} <br></span>

                      <b>الحى:</b>  {{data.address[0].zone}}<br>
                            </address>
            </div>
            <!-- /.col -->
            <div class="col-sm-4 invoice-col">
                <b>رقم الطلب:</b> {{data.id}}<br>
                <b>حالة الدفع:</b> {{data.payment_status}}<br>
                <b>طريقة الدفع:</b> {{data.orderDetail.payment_type}}<br>
                <b>عدد السلات:</b> {{data.orderDetail.package_quantity}}<br>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->


        <!-- Table row -->
        <div class="row">

          <div class="col-xs-12 table-responsive">
              <table class="table table-striped">
                  <thead>
                  <tr>
                      <th>الاسم</th>
                      <th>السعر</th>
                      <th>الكميه</th>
                      <th>الاجمالى</th>
                      <th></th>
                  </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let product of data.allproducts">
                          <td>{{product.title}}</td>
                          <td>{{product.price}}</td>
                          <td>{{product.quantity}}</td>
                          <td>{{product.total_price}}</td>
                          <td>
                            <span *ngIf="product.deleted_at"  style="color:red">تم الالغاء</span>
                            </td>
                      </tr>
                              </tbody>
              </table>
          </div>


            <div class="col-xs-12 table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th>الاسم</th>
                        <th>السعر</th>
                        <th>الكميه</th>
                        <th>الاجمالى</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of data.allproducts">
                            <td>{{product.title}}</td>
                            <td>{{product.price}}</td>
                            <td>{{product.quantity}}</td>
                            <td>{{product.total_price}}</td>
                            <td>
                              <span *ngIf="product.deleted_at"  style="color:red">تم الالغاء</span>
                              </td>
                        </tr>
                                </tbody>
                </table>
            </div>
            <!-- /.col -->
        </div>

        <!-- /.row -->

        <div class="row">
            <!-- accepted payments column -->
            <!-- /.col -->
            <div class="col-xs-6">
                <div class="table-responsive">
                    <table class="table">
                        <tbody><tr>
                            <th style="width:50%">المجموع:</th>
                            <td>
                              {{data.sub_total}}  ريال
                              </td>
                        </tr>
                        <tr>
                            <th>ضرائب </th>
                            <td>{{data.tax}} ريال</td>
                        </tr>
                        <tr>
                            <th>شحن:</th>
                            <td>{{data.shipping}}  ريال</td>
                        </tr>
                        <tr>
                            <th>خصم:</th>
                            <td>{{data.discount}}  ريال</td>
                        </tr>
                        <tr>
                            <th>المحفظة:</th>
                            <td>{{data.wallet}}  ريال</td>
                        </tr>

                        <tr>
                            <th>المجموع الكلى:</th>
                            <td>  {{data.total}}  ريال</td>
                        </tr>
                        </tbody></table>
                </div>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
</section>
<section *ngIf="!data" style="margin-top:150px;    text-align: center;
    margin-bottom: 60px;" >
<h5>{{msg}}</h5>
</section>
    <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->

    <footer class="main-footer">
      <div class="pull-right hidden-xs">
      </div>
      <strong style="margin: auto;text-align: center; margin-bottom:10px">Copyright &copy; 2020 <a href=""></a>مقضاك.</strong>
    </footer>

</div>
<div *ngIf="!is_loding" style="margin-top:200px;text-align:center">
  <h5>جارى تحميل الفاتورة...</h5>
</div>
